.categorytopbar{
    width: 100%;
    padding: 10px 20px;
    border: 2px solid var(--col1);
    border-radius: 35px;
    margin: 20px 0px;
}

@media screen and (min-width: 800px) {
    .categorytopbar{
        display: none;
    }
}