.product {
    display: flex;
    /* width: 226px; */
    min-width: 226px;
    height: 331px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.083);
}

.wishlistbtn, .wishlistbtn1{
    width: 25px;
    height: 25px;
    /* background-color: aqua; */
    position: absolute;
    top: 10px;
    right: 10px;
}
.wishlistbtn svg{
    color: #5b5b5b;
}

.wishlistbtn svg:hover{
    color: var(--col1);
    cursor: pointer;
}

.wishlistbtn1 svg{
   fill: #ff78ae;
   color: #ff78ae;
}

.product .s1 {
    height: 65%;
    width: 100%;
    overflow: hidden;
    padding: 20px;
}

.product .s1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.5s ease;
}

.product .s1 img:hover {
    scale: 1.1;
}

.product .s2 {
    height: 20%;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.product h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    width: 90%;
}

.product h3 span {
    text-decoration: line-through;
    font-size: 15px;
    color: gray;
    margin-left: 5px;
}

.product .s2 p {
    font-size: 15px;
    font-weight: 300;
    margin: 0px;
    width: 90%;
}


.product .s3 {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 0px;
}

.product .s3 p {
    font-size: 15px;
    font-weight: 500;
    margin: 0px;
    /* background-color: var(--col1); */
    padding: 5px 0px;
    border-radius: 5px;
    color: #5b5b5b;
    width: 90%;
}

.product .addbtn {
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.product .addbtn svg {
    width: 30px;
    height: 30px;
    background-color: var(--col1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 5px;
    color: white;
    transition: all 0.5s ease;
}

.product .addbtn svg:hover {
    background-color: var(--col1);
    color: white;
    transform: scale(1.2);
    cursor: pointer;
}

.product .addbtn .qty{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.128);
    /* overflow: hidden; */
    position: relative;
}
.product .closebtn{
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    background-color: var(--col1);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 100;
    border: none;
    line-height: 0px;
    cursor: pointer;
}

.product .addbtn .qty .qtybtn{
    width: 35px;
    border: none;
    background-color: white;
    
    color: var(--col2);
    font-size: 25px;
    font-weight: 500;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
}

.product .addbtn .qty p{
    font-size: 20px;
    font-weight: 500;
    color: var(--col2);
    margin: 0px;
    padding: 0px;
}

.product .addtocart{
    border: none;
    background-color: var(--col1);
    color: white;
    padding: 5px;
    border-radius: 5px;
}