.auth-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.auth-popup__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 80%;
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 10px;
    /* overflow: scroll; */
}

/* @media screen and (max-width: 768px) {
    .auth-popup__content {
        width: 90%;
        height: 90%;
    }
} */
    

/* .auth-popup__content__small{
    width: 40%;
    height: 50%;
} */
.auth-popup__content .login {
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.auth-popup__content .login .login__img {
    /* width: 60%; */
    width: 50%;
    height: 100%;
    background-color: lightgray;
}

.auth-popup__content .login .login__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.auth-popup__content .login form{
    width: 50%;
    padding: 20px;
    overflow-y: scroll;
}
.auth-popup__content .fullform form{
    width: 100%;
    padding: 20px;
    overflow-y: scroll;
}
.auth-popup__content form::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
}

.auth-popup__content form::-webkit-scrollbar-thumb {
    background-color: var(--col1);
    border-radius: 5px;
    border: 1px solid lightgrey;
}


.auth-popup__content form .formcont {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.auth-popup__content form .formcont input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 10px;
    outline: none;
}

.auth-popup__content form .formcont input:focus {
    border: 1px solid var(--col1);
}

.auth-popup__content form .formcont label {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 3px;
    color: grey;
}

.auth-popup__content form .formcont1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;

}

.auth-popup__content form .formcont1 .row {
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    /* background-color: aqua; */
    align-items: center;
    padding: 5px 0px;
}

.auth-popup__content .formcont1 a {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 3px;
    color: grey;
    text-decoration: none;
}

.auth-popup__content .btn {
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--col1);
    color: #fff;
    font-size: 17px;
    font-weight: 300;
    cursor: pointer;
    padding: 15px;
    margin: 20px 0px;
    opacity: 0.8;
}

.auth-popup__content .btn:hover {
    background-color: var(--col1);
    color: white;
    opacity: 1;
}

.auth-popup__content form .s1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.auth-popup__content form .s1 img {
    height: 50px;
    object-fit: contain;
    margin-bottom: 20px;
}

.auth-popup__content form .s1 h1 {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 10px;
    color: black;
}

.auth-popup__content form .s1 p {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 10px;
    color: grey;
}

.auth-popup__content form .s1 p a {
    color: var(--col1);
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
}

.auth-popup__close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 30px;
    color: var(--col1);
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    opacity: 0.8;
    outline: none;
    border: none;
    font-weight: 100;
    z-index: 1000;
}

.auth-popup__close-btn:hover {
    opacity: 1;
}


/* make this responsive */

@media screen and (max-width: 1324px) {
    /* change img width */
    .auth-popup__content .login .login__img {
        width: 50%;
    }

    /* change form width */
    .auth-popup__content form {
        width: 50%;
    }

    .auth-popup__content .login .login__img {
        object-fit: cover;
    }
}

@media screen and (max-width: 1024px) {
    .auth-popup__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 90%;
        background-color: #fff;
        /* padding: 20px; */
        border-radius: 10px;
        /* overflow: scroll; */
    }

    .auth-popup__content .login {
        flex-direction: column;
    }

    .auth-popup__content .login .login__img {
        /* width: 100%;
        height: 50%;
        object-fit: cover; */
        display: none;
    }
    .auth-popup__content .login .login__img img{
        width: 100%;
        height: 100%;
    }
    .auth-popup__content .login form{
        width: 100%;
    }


    .auth-popup__content{
        overflow: scroll;
    }

    .auth-popup__content::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: #f5f5f5;
    }
    
    .auth-popup__content::-webkit-scrollbar-thumb {
        background-color: var(--col1);
        border-radius: 5px;
        border: 1px solid lightgrey;
    }
}



@media screen and (max-width: 500px){
    .auth-popup__content form .formcont1{
        padding: 0px;
    }
    /* decrease input and label size and all heads */
    .auth-popup__content form .formcont input {
        height: 30px;
        padding: 20px 10px;
    }

    .auth-popup__content form .formcont label {
        font-size: 15px;
    }

    .auth-popup__content form .formcont1 a {
        font-size: 15px;
        text-align: center;
width: 100%;
    
    }

    .auth-popup__content form .s1 h1 {
        font-size: 20px;
    }

    .auth-popup__content form .s1 p {
        font-size: 15px;
        text-align: center;
    }

    .auth-popup__content form .s1 img {
        width: 90%;
    }

    .auth-popup__content form .s1 p a {
        font-size: 15px;
    }

    
} 