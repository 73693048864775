.footer1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: 20px 0px; */
    background-color: rgb(240, 240, 240);
    /* height: 350px; */
    margin-top: 30px;
    overflow: hidden;
}

.footer1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media screen and (max-width: 768px){
    .footer1{
        display: none;
    }
}