.product_sidebar {
    /* display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 20px; */

    /* grid */
    display: grid;
    /* 1/4 for  product_sidebar1 */
    /* 3/4 for  product_sidebar2 */
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
    padding: 0px 20px;
    /* grid */
}

@media screen and (max-width: 1000px){
    .product_sidebar{
        grid-template-columns: 1fr 2fr;
    }
}

@media screen and (max-width: 800px){
    .product_sidebar{
        grid-template-columns: 1fr;
    }
}

.scrollToTop{
    position: fixed;
    z-index: 9999;
    background: var(--col1);
    width: 50px;
    height: 50px;
    bottom: 50px;
    border-radius: 50px;
    right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
}