.dropdowncomponent {
    position: relative;
}

.dropdowncomponent h3 {
    font-size: 15px;
    margin: 0px;
    padding: 0px;
}

.dropdowncomponent .s1 h3:hover {
    color: var(--col1);
    /* border-bottom: 1px solid var(--col1); */
}



.dropdowncomponent .s1 {
    background-color: white;
    cursor: pointer;
}

.dropdowncomponent .s2 {
    position: absolute;
    z-index: 100;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 200px;
    top: 55px;
    left: 20px;
    padding: 20px 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.075);
    max-height: 400px;
    overflow-y: scroll;
}

.dropdowncomponent .s2::-webkit-scrollbar {
    width: 5px;
}

.dropdowncomponent .s2::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.dropdowncomponent .s2::-webkit-scrollbar-thumb {
    background: #888;
}

.dropdowncomponent .s2 .category {
    /* background-color: aqua; */
    width: 100%;
    padding: 5px 25px;
    color: rgb(89, 89, 89);
}


.dropdowncomponent .s2 .category h3 {
    font-size: 13px;
}

.dropdowncomponent .s2 .category:hover {
    background-color: rgb(229, 231, 235);
    color: black;
}


.dropdowncomponent .s2 .category h3:hover {
    color: black;
}


/* .dropdowncomponent .s1 .border{
    background-color: var(--col1);
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: -20px;
    z-index: 120;
} */

/* make border animated from left to right on load */



.dropdowncomponent .s1 .border {
    background-color: var(--col1);
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: -20px;
    z-index: 120;
    animation: borderanimation 0.5s ease-in-out;
}

@keyframes borderanimation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}



.droptitle{
    display: flex;
    align-items: center;
    gap: 10px;
}

.droptitle svg{
    width: 15px;
    height: 15px;
}