.changepassword{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.changepassword .form{
    align-items: center;
    /* background-color: aqua; */
    justify-content: center;
}
.changepassword .form .form-group{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin: 5px 0;
    /* background-color: aqua; */
    max-width: 500px;
    justify-content: space-between;
}