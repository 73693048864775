/* Sucess.css */

/* Container styles */
.success-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  /* Heading styles */
  .success-heading {
    font-size: 24px;
    color: #008000; /* Green color for success message */
    margin-bottom: 10px;
  }
  
  /* Message styles */
  .success-message {
    font-size: 16px;
    color: #333; /* Dark gray color for text */
    margin-bottom: 20px;
  }
  
  /* Order summary styles */
  .order-summary {
    background-color: #f9f9f9; /* Light gray background for order summary */
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  /* Shipping information styles */
  .shipping-info {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  /* Payment information styles */
  .payment-info {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  /* Order number styles */
  .order-number {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* Continue shopping button styles */
  .continue-shopping-btn {
    background-color: #008000;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .continue-shopping-btn:hover {
    background-color: #006600; /* Slightly darker green on hover */
  }