nav {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    border-bottom: 1px solid rgb(231, 231, 231);
}

nav svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.403);
}

nav svg:hover {
    color: var(--col1);
}

nav .s1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(243, 246, 249);
    padding: 10px 20px;
}

nav img {
    width: 150px;
}

nav .searchbar {
    display: flex;
    border: none;
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); */
    border-radius: 2px;
    overflow: hidden;
    padding: 10px 20px;
    justify-content: space-between;
    max-width: 500px;
    width: 100%;
    background-color: white;
}

nav .searchbar input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    font-weight: 100;
}

nav .searchbar input:focus{
    outline: var(--col1);
    width: 100%;
    font-size: 14px;
    font-weight: 100;
}

nav .searchbar svg {
    color: rgba(89, 89, 89, 0.654);
}

nav .searchbar svg:hover {
    background-color: var(--col1);
    color: white;
    border-radius: 50%;
    padding: 5px;
}



nav .searchbar button {
    border: none;
    background-color: transparent;
    cursor: pointer;

}

nav .s1 .right {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

nav .cart {
    position: relative;
    margin: 0px 15px;
    display: flex;
    justify-content: center;
}

nav .wishout{
    margin: 0px;
}
nav .wishout svg{
    color: rgba(0, 0, 0, 0.403);
    cursor: pointer;
}
nav .cartout{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}
nav .cartout p{
    font-size: 15px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
    cursor: pointer;
}
nav .cartout:hover{
    color: var(--col1);
} 
nav .cart .qty {
    background-color: var(--col1);
    color: white;
    border-radius: 50%;
    /* padding: 1px; */
    position: absolute;
    width: 25px;
    height: 25px;
    top: -15px;
    right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
}


/* /////////////////////////////////////// */
nav .user {
    position: relative;
    margin: 0px 3px;
    display: flex;
    justify-content: center;
}
nav .userout{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}
nav .userout p{
    font-size: 15px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
    cursor: pointer;
}
nav .userout:hover{
    color: var(--col1);
} 
/* ///////////////////////////////////// */
nav .s2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    background-color: white;
}

nav .s21{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 0px 10%;
}

nav .s22{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav .s22 h3{
    font-size: 15px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin: 0 3px;
    padding: 0;
}

nav .s22 p{
    margin: 0px 5px;
    padding: 0px;
    font-size: 15px;
    font-weight: 700;
    color: var(--col1);
    line-height: 0px;
}


/* free delivery */
.freedeliveryout{
    display: flex;
    flex-direction: column;
    width: 200px;
}

.freedeliveryout p{
    font-size: 10px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-align: center;
}

.freedeliveryout p span{
    font-size: 15px;
    font-weight: 500;
    color: var(--col1);
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.freedeliveryprogress{
    background-color: lightgray;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.freedelivery{
    width: 5px;
    height: 10px;
    background-color: var(--col1);
    border-radius: 10px;
}


 @media only screen and (max-width : 1250px) {
    nav  .s2{
        display: none;
    }
    nav .searchbar {
        display: none;
    }
    nav .freedeliveryout{
        display: none;
    }
} 

@media only screen and (max-width : 550px){
    nav .cartout p, nav .userout p, nav .wishout p{
        display: none;
    }

    nav img{
        width: 100px;
    }

    nav .cartout , nav .wishout , nav .userout{
        scale: 0.8;
        margin: 0 0px;
        
    }
}