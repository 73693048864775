.bannerslider {
    width: 100%;
    overflow: hidden;
    position: relative;
    min-height: max-content;
}

.bannerslider .imagecont {
    background-color: rgb(255, 255, 255);
    display: flex;
    position: relative;
    width: 100%;
    min-height: max-content;
}

.bannerslider .imagecont img {
   
    width: 100%;
    height: max-content;
    min-height: max-content;
    object-fit: cover;
}

.bannerslider .imagecont .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}